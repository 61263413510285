import { createContext, ReactNode, useContext, useReducer } from 'react';
import { Vendors } from '@codegen/cmsTypes';
import { ItineraryFragment } from '@codegen/gatewayUtils';
import ItineraryModal from '@ui/components/Itinerary/ItineraryModal';
import NoCheckedBagsModal from '@ui/components/Offer/NoCheckedBagsModal';
import TrustPilotWidget from '@ui/components/TrustPilotWidget/TrustPilotWidget';
import { useConstants } from '@web/context/ConstantContext';
import { useExperiments } from '@web/context/ExperimentContext';
import useDohopConnect from '@web/context/hooks/useDohopConnect';
import useItineraryConfig from '@web/context/hooks/useItineraryConfig';
import { useSettings } from '@web/context/SettingsContext';
import useTieredConnectionService from './Booking/hooks/useTieredConnectionService';
import TieredConnectionServiceModal from './Booking/TieredConnectionService/TieredConnectionServiceModal';
import DohopConnectRichInformationModal from './Modals/DohopConnectRichInformationModal/DohopConnectRichInformationModal';
import ProtectGroupInformationModal from './Modals/ProtectGroupInformationModal';

type ModalVariant =
  | 'dohop-connection-info'
  | 'no-checked-bags-info'
  | 'save-flights-info'
  | 'protect-group'
  | 'generic';

type Itinerary = {
  payload: {
    itinerary: ItineraryFragment;
    vendors: Vendors;
  };
  type: 'itinerary';
};
type ModalWithData = Itinerary;

type ModalWithoutData = { type: ModalVariant };

type ClearModal = { type: undefined };

type ModalAction = ModalWithData | ModalWithoutData | ClearModal;

type ExtractType<T> = T extends { type: infer U } ? U : never;

type ExtractPayload<T> = T extends { payload: infer U } ? U : never;

type ModalState = {
  data?: ExtractPayload<ModalWithData>;
  type?: ExtractType<ModalWithoutData | ModalWithData>;
};

const modalReducer = (_state: ModalState, action: ModalAction): ModalState => {
  return {
    data: 'payload' in action ? action.payload : undefined,
    type: action.type,
  };
};

export type ModalOrchestrator = {
  hideModal: () => void;
  showModalFn: (
    modalVariant: ModalState['type'],
    modalPayload?: ModalState['data'],
  ) => () => void;
};

const context = createContext<ModalOrchestrator>({
  hideModal: () => {},
  showModalFn: () => () => {},
});

export default context;

export const ModalOrchestratorContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { locale } = useConstants();
  const { residency } = useSettings();
  const [{ data, type }, dispatch] = useReducer(modalReducer, {
    data: undefined,
    type: undefined,
  });

  const { showItineraryVendorLogos, showSelfConnectList } =
    useItineraryConfig();

  const hideModal = () => dispatch({ type: undefined });

  const showModalFn =
    (modalVariant: ModalState['type'], modalPayload?: ModalState['data']) =>
    () => {
      if (modalPayload) {
        dispatch({
          type: modalVariant,
          payload: modalPayload,
        });
      } else {
        dispatch({
          type: modalVariant,
        } as ModalWithoutData);
      }
    };

  const { isTieredConnectionService } = useExperiments();
  const { allModalServices, tierId } = useTieredConnectionService();

  const { getName, richModalBulletpoints, richModalTitle } = useDohopConnect();
  const dohopConnectName = getName(tierId);

  return (
    <context.Provider value={{ hideModal, showModalFn }}>
      {children}

      {!isTieredConnectionService ? (
        <DohopConnectRichInformationModal
          isOpen={type === 'dohop-connection-info'}
          onOpenChange={hideModal}
          richModalBulletpoints={richModalBulletpoints}
          richModalTitle={richModalTitle}
          trustPilotWidget={
            <TrustPilotWidget
              height="100px"
              localeString={`${locale}-${residency}`}
              widgetType="mini"
            />
          }
        />
      ) : (
        <TieredConnectionServiceModal
          allModalServices={allModalServices}
          id="tiered-connection-service-info-modal"
          isOpen={type === 'dohop-connection-info'}
          onCloseModal={hideModal}
        />
      )}

      <ProtectGroupInformationModal
        isOpen={type === 'protect-group'}
        toggleModal={hideModal}
      />

      <NoCheckedBagsModal
        id="no-checked-bags-connection-modal-itinerary"
        isOpen={type === 'no-checked-bags-info'}
        locale={locale}
        onCloseModal={hideModal}
      />

      <ItineraryModal
        dohopConnectName={dohopConnectName}
        isOpen={type === 'itinerary'}
        itinerary={data?.itinerary}
        language={locale}
        onNoCheckedBagsClick={() => dispatch({ type: 'no-checked-bags-info' })}
        residency={residency}
        showItineraryVendorLogos={showItineraryVendorLogos}
        showSelfConnectList={showSelfConnectList}
        toggleInformationModal={() =>
          dispatch({ type: 'dohop-connection-info' })
        }
        toggleItineraryModal={hideModal}
        vendors={data?.vendors}
      />
    </context.Provider>
  );
};

export const useModalOrchestrator = () => useContext(context);
