import { PollResponseBookingsItem, ServiceBooking } from '@codegen/offerAPI';
import { Partner } from '@shared/types/enums';
import {
  BASE,
  DOHOP,
  PREMIUM,
} from '@utils/constants/tieredConnectionServiceConstants';

export const getTierId = ({
  isPremiumTier,
  isTieredConnectionService,
  partner,
}: {
  isPremiumTier: boolean;
  isTieredConnectionService?: boolean;
  partner: string;
}) => {
  if (!isTieredConnectionService && partner !== Partner.Dohop) {
    return DOHOP;
  }

  return isPremiumTier ? PREMIUM : BASE;
};

export const scrollToProtection = () => {
  if (typeof document === 'undefined') {
    return;
  }

  const input = document.querySelector(
    'button[data-cy=continue-button-service-tier-premium]',
  );

  input?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const getTierIdFromTransferUrl = (transferURL: string) => {
  const params = new URLSearchParams(transferURL.split('?')[1]);

  // Get the 'is_tiered' parameter from the URL
  const tieredParams = params.get('is_tiered');
  const isTiered = tieredParams?.toLowerCase() === 'true';

  return isTiered ? BASE : DOHOP;
};

export const getTierIdFromBookings = (
  bookings: PollResponseBookingsItem[],
): string => {
  const tierBooking = bookings.find(
    (booking): booking is ServiceBooking => 'tier' in booking,
  );

  if (!tierBooking?.tier) {
    return DOHOP;
  }

  return tierBooking.tier;
};
