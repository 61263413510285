import {
  TieredServicePackageFragment,
  useGetTieredConnectionServicesQuery,
} from '@codegen/cmsUtils';
import { DohopService } from '@codegen/offerAPI';
import { useConstants } from '@web/context/ConstantContext';
import { useExperiments } from '@web/context/ExperimentContext';
import useGetOffer from '@web/context/hooks/useGetOffer';
import { getTierId } from '../TieredConnectionService/utils/tieredConnectionServiceUtils';
import { usePassengers } from './usePassengers';

export type TieredPackage = Omit<
  TieredServicePackageFragment,
  'serviceTierId'
> & {
  serviceTierId: 'service-tier-premium' | 'service-tier-base';
};

const useTieredConnectionService = () => {
  const { isTieredConnectionService } = useExperiments();
  const { locale, partner } = useConstants();
  const { data } = useGetTieredConnectionServicesQuery(
    { locale },
    { retry: 2, enabled: isTieredConnectionService },
  );

  const { offer } = useGetOffer();
  const { passengers } = usePassengers();
  const tieredServices =
    offer?.all_pax_services.filter(
      (service): service is DohopService =>
        service.service_class === 'dohop_service_fee',
    ) ?? [];

  const isPremiumTier = tieredServices.some(
    (service) =>
      service.service_id === 'service-tier-premium' && service.quantity > 0,
  );

  const tierId = getTierId({
    isTieredConnectionService,
    isPremiumTier,
    partner,
  });

  const selectedServiceAmount =
    tieredServices.find((service) => service.quantity === 1)?.price.amount ?? 0;

  const notSelectedServiceAmount =
    tieredServices.find((service) => service.quantity === 0)?.price.amount ?? 0;

  const deltaAmount = selectedServiceAmount - notSelectedServiceAmount;
  const deltaAmountPerPassenger = deltaAmount / passengers.length;

  return {
    tieredPackages: data?.allTieredServicePackages as
      | TieredPackage[]
      | undefined,
    deltaAmountPerPassenger,
    deltaAmount,
    tieredServices,
    tierId,
    allModalServices: data?.tieredConnectionModalService?.modalServices,
  };
};

export default useTieredConnectionService;
