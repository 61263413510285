import { useDohopConnectQuery } from '@codegen/cmsUtils';
import { Partner } from '@shared/types/enums';
import {
  BASE,
  BASE_TIER_NAME,
  DOHOP,
  PREMIUM,
  PREMIUM_TIER_NAME,
} from '@utils/constants/tieredConnectionServiceConstants';
import { dohopConnectQueryPlaceholderData } from '@utils/queryUtils';
import { getVendorLink } from '@utils/vendorUtils';
import { useConstants } from '../ConstantContext';
import { useSettings } from '../SettingsContext';

const useDohopConnect = () => {
  const { locale, partner } = useConstants();
  const { residency } = useSettings();
  const { data } = useDohopConnectQuery(
    { locale },
    {
      keepPreviousData: true,
      placeholderData: dohopConnectQueryPlaceholderData,
    },
  );

  if (!data?.dohopConnect) {
    throw new Error('Failed to fetch dohop connect information');
  }

  const defaultSupportLink = `https://support.dohop.com/hc/${locale}`;

  const getTierName = ({
    localizedName,
    name,
    tierId,
  }: {
    localizedName?: string;
    name: string;
    tierId: string;
  }) => {
    switch (tierId) {
      case PREMIUM:
        return PREMIUM_TIER_NAME;
      case BASE:
        return BASE_TIER_NAME;
      case DOHOP:
      default:
        return localizedName || name;
    }
  };

  const getName = (tierId: string) => {
    if (!data.dohopConnect) {
      throw new Error('Failed to fetch dohop connect information');
    }
    const tierHack = partner !== Partner.Dohop ? DOHOP : tierId;

    return getTierName({
      tierId: tierHack,
      localizedName: data.dohopConnect.localizedName?.value,
      name: data.dohopConnect.name,
    });
  };

  return {
    getName,
    supportLink:
      getVendorLink({
        links: data.dohopConnect.supportLinks,
        locale,
        residency,
      })?.link || defaultSupportLink,
    termsLink: getVendorLink({
      links: data.dohopConnect.termsLinks,
      locale,
      residency,
    }),
    partialBookingLink:
      getVendorLink({
        links: data.dohopConnect.partialBookingLinks,
        locale,
        residency,
      })?.link || defaultSupportLink,
    logo: data.dohopConnect.logo,
    dohopIconConfig: data.dohopConnect.iconConfig,
    exitRowTermsLink:
      getVendorLink({
        links: data.dohopConnect.exitRowTermsLinks,
        locale,
        residency,
      })?.link || defaultSupportLink,
    supportEmail: data.dohopConnect.supportEmail,
    richModalTitle: data.dohopConnect.richModalTitle.value,
    richModalBulletpoints: data.dohopConnect.richModalBulletpoints,
    richSectionTitle: data.dohopConnect.richSectionTitle,
    richSectionItems: data.dohopConnect.richSectionItems,
    richSectionTitleVis: data.dohopConnect.richSectionTitleVis,
    richSectionSemiTitleVis: data.dohopConnect.richSectionSemiTitleVis,
    richSectionItemsVis: data.dohopConnect.richSectionItemsVis,
  };
};

export default useDohopConnect;
