import { ReactNode } from 'react';
import { TieredConnectionBenefitFragment } from '@codegen/cmsUtils';
import Box from '@ui-v2/core/Box/Box';
import Modal from '@ui-v2/core/Modal/Modal';
import OneButtonModalFooter from '@ui-v2/core/Modal/OneButtonModalFooter';
import Text from '@ui-v2/core/Text/Text';
import {
  BASE_TIER_NAME_SHORT,
  PREMIUM_TIER_NAME,
} from '@utils/constants/tieredConnectionServiceConstants';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import { TieredPackage } from '../hooks/useTieredConnectionService';

const PremiumTier = () => (
  <Box
    bg="components.tieredConnection.interactive.default"
    border="subdued"
    borderRadius="borderRadiusS"
    p={4}
  >
    <Text as="div" lineHeight="1" variant="heading-7">
      {PREMIUM_TIER_NAME}
    </Text>
  </Box>
);

const BaseTier = () => (
  <Box display="flex" flexDirection="row" gap={4}>
    <Box
      bg="surface.secondary"
      border="subdued"
      borderRadius="borderRadiusS"
      height="auto"
      p={4}
    >
      <Text as="div" lineHeight="1" variant="heading-7">
        {BASE_TIER_NAME_SHORT}
      </Text>
    </Box>
    <PremiumTier />
  </Box>
);

export const includedMap: Record<TieredPackage['serviceTierId'], ReactNode> = {
  'service-tier-premium': <PremiumTier />,
  'service-tier-base': <BaseTier />,
};

const TieredConnectionServiceModal = ({
  allModalServices,
  id,
  isOpen,
  onCloseModal,
}: {
  allModalServices?: TieredConnectionBenefitFragment[];
  id: string;
  isOpen: boolean;
  onCloseModal: () => void;
}) => {
  const { t } = useCmsTranslation();

  if (!allModalServices) {
    return null;
  }

  return (
    <Modal
      footer={
        <OneButtonModalFooter
          onOpenChange={onCloseModal}
          text={t('got_it', 'Got it')}
        />
      }
      header={
        <Box display="flex" flexDirection="column" gap={8}>
          <Text as="h3" variant="heading-3">
            {t('service_details', 'Service details')}
          </Text>
          <Text as="span" variant="body-2">
            {t(
              'service_details_description',
              'Below is more detailed information on what exactly is included in our services.',
            )}
          </Text>
        </Box>
      }
      id={id}
      isOpen={isOpen}
      maxWidth={750}
      mobileSize="full-screen"
      onOpenChange={onCloseModal}
    >
      <Box>
        {allModalServices.map((service) => (
          <Box
            borderBottom="subdued"
            display="flex"
            flexDirection="column"
            key={service.id}
            mb={16}
            pb={16}
          >
            <Box maxWidthCustom="70%" mb={4}>
              <Text as="h5" variant="heading-5">
                {service.title.value}
              </Text>
            </Box>
            <Box maxWidthCustom="80%" mb={12}>
              <Text as="p" colour="text.default" variant="body-2">
                {service.description.value}
              </Text>
            </Box>
            <Box alignItems="center" display="flex" flexDirection="row" gap={8}>
              <Text as="p" variant="heading-6">
                {t('included_in', 'Included in:')}
              </Text>
              {
                includedMap[
                  service.serviceTierId as TieredPackage['serviceTierId']
                ]
              }
            </Box>
          </Box>
        ))}
      </Box>
    </Modal>
  );
};

export default TieredConnectionServiceModal;
