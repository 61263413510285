export const DOHOP = 'dohop-connect';

export const PREMIUM_TIER_NAME = 'All-The-Way Advantage';

export const BASE_TIER_NAME = 'Basic Booking';

export const BASE_TIER_NAME_SHORT = 'Basic';

export const PREMIUM = 'premium';

export const BASE = 'base';
